import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import { BsFacebook } from 'react-icons/bs'



const footer = () => {
  return (
    <footer>
      
      <ul className='permalinks'>
        <li className='footer-links'> <a href="#">Home</a></li>
        <li className='footer-links'> <a href="#">About</a></li>
        <li className='footer-links'> <a href="#">Experience</a></li>
        <li className='footer-links'> <a href="#">Portfolio</a></li>
        <li className='footer-links'> <a href="#">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://facebook.com"> <BsFacebook/></a>
        <a href="https://instagram.com"><BsInstagram/></a>
        <a href="https://twitter.com"><BsTwitter/></a>
        <a href="https://twitter.com"><BsLinkedin/></a>
      </div>

      <a href="#" className='footer__title'>Designed &amp; built by Ryan Hynes</a>

    </footer>
  )
}

export default footer