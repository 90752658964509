import React from 'react'
import './About.css'
import ME from '..//../assets/me.png'
import {BsAwardFill} from 'react-icons/bs'
import {BiRightArrow} from 'react-icons/bi'

const About = () => {
  return (
    <section id='About'>
      <h5>Get to know me!</h5>
      <h2>&lt;About Me&gt;</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className="about__me-image">
            <img src={ME} alt="ME" />
          </div>
        </div>
        <div className='about__content'>
          <p>Hello, my name is Ryan Hynes. I make things that live on the internet. 
            My interests range from Web development to data science and
            machine learning. What started as a few lockdown induced
            <a href="https://www.python.org/"> Python </a>coding tutorials, quickly turned into a passion for Computer science.
            I was amazed at the the kinds of things that were possible with just a limited knowledge.
            </p> 
            <p>Fast forward a few months and I was studying for a Masters in Computing at   
              <a href='https://google.co.uk'> Cardiff University</a> with no prior STEM background. The learning curve was steep but it was
            worthwhile and ultimately really rewarding. The next step in my journey is to get valuable experience
            working as a developer or data scientist, to further improve my skills.</p>
          <div className='about__skills'>
            <p>Here are some of the technologies I've been using recently:</p>
            <ul className='skill-list'>
              <li className='skills'><BiRightArrow className='arrow-icon'/>
                <a href="https://reactjs.org/">ReactJS</a>
              </li>
              <li className='skills'><BiRightArrow className='arrow-icon'/>
                <a href="https://www.djangoproject.com/">Django</a>
              </li>
              <li className='skills'><BiRightArrow className='arrow-icon'/>
                <a href="https://www.mongodb.com/">MongoDB</a>
              </li>
              <li className='skills'><BiRightArrow className='arrow-icon'/>
                <a href="https://flask.palletsprojects.com/en/2.1.x/">Flask</a>
              </li>
              <li className='skills'><BiRightArrow className='arrow-icon'/>
                <a href="https://www.tensorflow.org/">TensorFlow</a>
              </li>
              <li className='skills'><BiRightArrow className='arrow-icon'/>
                <a href="https://keras.io/">Keras</a>
              </li>
            </ul>
          </div> 
          <a href="#Contact" className='btn btn-primary'>Let's talk</a>
        </div>
      </div>
    </section>
  )
}

export default About
