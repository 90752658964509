import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio2.jpeg'
import IMG2 from '../../assets/portfolio5.png'
import IMG3 from '../../assets/portfolio6.jpeg'


const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Sports Analytics dashboard web app',
    github: 'https://github.com/RyanHynes94',
    demo: 'https://dribble.com'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Plotly Data Visualisation',
    gihtub: 'https://github.com/RyanHynes94',
    demo: 'https://dribble.com'
  },

  {
    id: 3,
    image: IMG3,
    title: 'ReactJS portfolio website',
    gihtub: 'https://github.com/RyanHynes94',
    demo: 'https://dribble.com' 
  }
]



const portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>This is what i've been working on!</h5>
      <h2>&lt;My Portfolio&gt;</h2>
      <div className='container portfolio__container'>
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
                  <a href={github} className='btn'>Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default portfolio

