import React from 'react'
import './header.css'
import CTA from './CTA'
import Earth from '../../assets/earth.png'
import HeaderSocial from './HeaderSocials'

const header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello, my name is </h5>
        <h1>Ryan Hynes.</h1>
        <h4 className='job_title'>Data Engineer</h4>
        <CTA/>
        <HeaderSocial/>

        <div className='earth'>
          <img src={Earth} alt="earth" />
        </div>

        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default header