import React from 'react'
import './experience.css'
import {BsPatchCheck} from 'react-icons/bs'

const experience = () => {
  return (
    <section id='experience'>
      <h5>My skills and expertise!</h5>
      <h2>&lt;Experience&gt;</h2>

      <div className='container experience__container'>
        <div className="experience__frontend">
          <h3>Web Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>3 years</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>React</h4>
                <small className='text-light'>1 year</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>CSS</h4>
                <small className='text-light'>3 years</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>Javascript</h4>
                <small className='text-light'>3 years</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>Django</h4>
                <small className='text-light'>2 years</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>Flask</h4>
                <small className='text-light'>2 years</small>
              </div>
            </article>
          </div>
        </div>
        {/* End of Web development */}
        <div className="experience__datascience">
          <h3>Data Science</h3>
          <div className="experience__content">
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>Python</h4>
                <small className='text-light'>3+ years</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>SQL</h4>
                <small className='text-light'>2 years</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>Data Vis</h4>
                <small className='text-light'>2 years</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>TensorFlow</h4>
                <small className='text-light'>6 months</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>Pandas</h4>
                <small className='text-light'>2 years</small>
              </div>
            </article>
            <article className='experience__details'>
            <BsPatchCheck className='experience__details-icon'/>
              <div>
                <h4>MongoDB</h4>
                <small className='text-light'>1 year</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default experience