import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'


 
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com" target='_blank'><BsLinkedin /></a>
        <a href="https://www.githhub.com" target='_blank'><BsGithub /></a>
        <a href="https://www.twitter.com" target='_blank'><BsTwitter /></a>
        <a href="https://www.instagram.com" target='_blank'><BsInstagram/></a>
    </div>
  )
}

export default HeaderSocials